/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class CashLogService {
  getCashLogLists(queryParams) {
    const pars = {
      balanceType: queryParams.balanceType,
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      phone: queryParams.phone,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      type: queryParams.type,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/txns/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          cashlogs: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getBalanceAccount(queryParams) {
    const pars = {
      balanceType: queryParams.balanceType,
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      phone: queryParams.phone,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      type: queryParams.type,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/User/balance", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          cashlogs: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  getOutStandingBalance(queryParams) {
    const pars = {
      userId: queryParams.userId,
    };
    return instance
      .get("/api/User/balance-outstanding", { headers: authHeader(), params: pars })
      .then((response) => {
        return response;
      });
  }

  getTxnType() {
    return instance.get("/api/txn-type/index", { headers: authHeader() });
  }

  getTotalOutStandingBalance(id) {
    return instance
      .get("/api/txns/index", { headers: authHeader(), params: { userId: id } })
      .then((response) => {
        const pars = {
          userId: id,
          pageSize: response.data.count,
        };
        return instance
          .get("/api/txns/index", { headers: authHeader(), params: pars })
          .then((res) => {
            const total = res.data.data.reduce(
              (n, { net_amount }) => n + parseInt(net_amount),
              0
            );
            return total;
          });
      });
  }

  getBetHistory(queryParams) {
    const pars = {
      userId: queryParams.userId,
      productId: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      pageNumber: queryParams.page,
      type: queryParams.balanceType,
      status: queryParams.type,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/User/bet-history", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          cashlogs: response.data.data,
          total: response.data.count,
          money: response.data.total,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getCallHistory(queryParams) {
    const pars = {
      userId: queryParams.userId,
      // productId: queryParams.balanceType,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/User/call-history", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          cashlogs: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getTitleMessage() {
    return instance.get("/api/message-title/index", { headers: authHeader() });
  }
  getMessageHistory(queryParams) {
    const pars = {
      // balanceType: queryParams.balanceType,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      message_title_id: queryParams.message_title_id,
      username: queryParams.username,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      phone: queryParams.phone,
      // u: queryParams.todate,
      // type: queryParams.type,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/message-log/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          cashlogs: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
}

export default new CashLogService();
