<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("Transaction id") }}</label>
          <b-form-input
            v-model="filter.reference"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-2">
          <label>{{ $t("Transaction Source") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.balance_type_from"
            :options="balanceType"
            class="w-100"
            :reduce="(val) => val.id"
            label="name"
          />
          <!-- @input="(val) => $emit('update:balanceTypeFromFilter', val)" -->
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label="$t('From')" label-for="fromDate">
            <flat-pickr
              id="fromDate"
              v-model="filter.fromdate"
              class="form-control"
              placeholder="YYYY-MM-DD HH:II:SS"
              @on-change="changeFromDate"
              :config="flatpickrConfig"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label="$t('To')" label-for="toDate">
            <flat-pickr
              id="toDate"
              v-model="filter.todate"
              class="form-control"
              placeholder="YYYY-MM-DD HH:II:SS"
              @on-change="changeToDate"
              :config="flatpickrConfig"
            />
          </b-form-group>
        </b-col>
        <!-- action -->
        <b-col cols="12" md="4" class="d-flex align-items-end justify-content-start" style="padding-bottom: 23px">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardBody,
    BButton,
    flatPickr,
    vSelect,
  },
  props: {
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    balanceType: {
      type: Array,
      required: true,
    },
    balanceTypeFromFilter: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      filter: {
        reference: this.referenceFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        balance_type_from: this.balanceTypeFromFilter,
      },
      flatpickrConfig: {
        dateFormat: 'Y-m-d H:i:S', // Set your desired format here
        enableTime: true, // Enable time selection
        time_24hr: true, // Use 24-hour format for time
      },
    };
  },
  methods: {
    changeFromDate() {
      // this.$emit('update:fromdateFilter', this.filter.fromdate)
    },
    changeToDate() {
      // this.$emit('update:todateFilter', this.filter.todate)
    },
    resetFilter() {
      this.filter = {
        reference: null,
        fromdate: null,
        todate: null,
        balance_type_from: null,
      };
      this.$emit("update:referenceFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:balanceTypeFromFilter", null);
    },
    searchFilter() {
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:balanceTypeFromFilter", this.filter.balance_type_from);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
