/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class DepositService {
  getDepositLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      bankCode: queryParams.bankCode,
      status: queryParams.stat,
      type: queryParams.type,
      pageNumber: queryParams.page,
      tag_id: queryParams.tags,
      bank_account_name: queryParams.bank_account_name,
      account_number: queryParams.account_number,
      member_bank_ref: queryParams.member_bank_ref,
    };
    return instance
      .get("/api/Deposit/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          deposits: response.data.data,
          total: response.data.count,
          money: response.data.total,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  exportDeposits(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      reference: queryParams.reference,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      bankCode: queryParams.bankCode,
      status: queryParams.stat,
      type: queryParams.type,
      // pageNumber: queryParams.page,
    };
    const data = {};
    return instance.post("/api/Deposit/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }

  updateLocalDepositStatus(depositData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("localDepositId", depositData.id);
    data.append("status", depositData.status);
    data.append("rejectReason", depositData.remark);

    return instance.post("/api/local-deposit/update-status", data, {
      headers: authHeader(),
    });
  }

  updateDepositStatus(depositData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("depositId", depositData.id);
    data.append("status", depositData.status);
    data.append("rejectReason", depositData.remark);

    return instance.post("/api/Deposit/update-status", data, {
      headers: authHeader(),
    });
  }
}

export default new DepositService();
