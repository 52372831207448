<template>
  <div>
    <!-- Filters -->
    <BetHistoryListFilters
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :type-filter.sync="typeFilter"
      :balance-type="balanceType"
      :option-type="optionType"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      :isProduct="true"
      @refetch-data="refetchData"
    />

    <div style="margin: 8px 0px; color: white">
      Tổng tiền: {{ numberFormat(parseFloat(totalMoney) / 1000) }}
    </div>
    <b-card v-if="fetchCashLogs" no-body class="border">
      <b-table
        ref="refCashLogsListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchCashLogs"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc":busy="loading"
      >
        <div slot="table-busy" class="text-center mt-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <!-- <template #cell(username)="data">
          <div v-if="data.item.user">
            {{ data.item.user.username }}
          </div>
        </template> -->

        <template #cell(source)="data">
          <div v-if="data.item.product_name">
            {{ data.item.product_name }}
          </div>
        </template>

        <template #cell(betAmount)="data">
          <div v-if="data.item.bet_amount !== null">
            {{ numberFormat(parseFloat(data.item.bet_amount) / 1000) }}
          </div>
          <div v-else>0</div>
        </template>
        <template #cell(Ref)="data">
          {{ data.item.txn_id }}
        </template>
        <template #cell(gameName)="data">
          {{ data.item.game_type_name ? data.item.game_type_name : '' }}
        </template>

        <template #cell(Type)="data">
          {{ resolveStatus(data.item.stat).label }}
        </template>

        <!-- <template #cell(payout_time)="data">
          {{ data.item.payout_time }}
        </template> -->

        <template #cell(created_at)="data">
          {{ data.item.created_at }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCashLogs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTh,
  BLink,
  BBadge,
  BPagination,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import store from "@/store";
import router from "@/router";
import cashLogStoreModule from "./cash-log/cashLogStoreModule";
import BetHistoryListFilters from "./cash-log/BetHistoryListFilters.vue";
import i18n from "@/libs/i18n";
import agentStoreModule from "@/views/agent/agentStoreModule";

export default {
  components: {
    BetHistoryListFilters,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  setup() {
    const perPage = ref(25);
    const totalCashLogs = ref("");
    const currentPage = ref(1);
    const totalMoney = ref(0);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const referenceFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const typeFilter = ref(null);

    const balanceType = ref([]);
    const optionType = [
      { name: "bet place", value: 1 },
      { name: "win", value: 2 },
      { name: "lost", value: 3 },
      { name: "cancel", value: 4 },
      { name: "tie", value: 5 },
    ];

    const balanceTypeFromFilter = ref(null);
    const loading = ref(false);

    const refCashLogsListTable = ref(null);
    const toast = useToast();

    const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";

    // Register module
    if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
      store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);
    if (!store.hasModule('agent'))
      store.registerModule('agent', agentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
    });

    const tableColumns = [
      { key: "index", label: "#" },
      // { key: "username", label: "User Name" },
      { key: "source", label: "Source" },
      { key: "betAmount", label: "Bet amount" },
      { key: "Ref", label: "Ref" },
      { key: "gameName", label: "Game name" },
      { key: "Type", label: "Type" },
      // { key: "payout_time", label: "Payout time" },
      { key: "created_at", label: "Created At" },
      // { key: "closeBalance", label: "Close Balance" },

      // { key: 'updatedBy', label: i18n.t('Updated by') },
      // { key: "createdAt", label: "Created at" },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: i18n.t("Bet place") };
      if (status == 2) return { label: i18n.t("Win") };
      if (status == 3) return { label: i18n.t("Lost") };
      if (status == 4) return { label: i18n.t("Cancel") };
      if (status == 5) return { label: i18n.t("Tie") };
    };

    const refetchData = () => {
      refCashLogsListTable.value.refresh();
    };

    watch(
      [
        currentPage,
      ],
      () => {
        refetchData();
      }
    );
    store.dispatch("app-user/fetchUserBalanceType").then((response) => {
      balanceType.value = response.data.data;
    });

    const fetchCashLogs = (ctx, callback) => {
      loading.value = true;
      store
        .dispatch("app-user-cash-log/fetchBetHistory", {
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          userId: router.currentRoute.params.id,
          reference: referenceFilter.value,
          fromdate: fromdateFilter.value,
          balanceType: balanceTypeFromFilter.value,
          todate: todateFilter.value,
          type: typeFilter.value,
        })
        .then((response) => {
          const { cashlogs, total, pageSize, money } = response;
          callback(cashlogs);
          totalCashLogs.value = total;
          perPage.value = pageSize;
          totalMoney.value = money;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refCashLogsListTable.value
        ? refCashLogsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCashLogs.value,
      };
    });

    return {
      fetchCashLogs,
      loading,
      refetchData,
      tableColumns,
      perPage,
      currentPage,
      totalCashLogs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refCashLogsListTable,

      numberFormat,
      formatDateTime,

      referenceFilter,
      fromdateFilter,
      todateFilter,
      typeFilter,
      balanceType,
      balanceTypeFromFilter,
      resolveStatus,
      optionType,
      totalMoney,
    };
  },
};
</script>
